import React, { useState, useRef } from "react";
import classes from "./HorizontalSidePanel.module.css";

const HorizontalSidePanel = (props) => {
  const [expandPanel, setExpandPanel] = useState(
    "open" in props ? props.open : true
  );
  const panelRef = useRef(null);

  return (
    <div
      className={
        expandPanel
          ? [
              classes.horizontal_side_panel,
              classes.side_panel_width,
              props.className,
            ].join(" ")
          : [classes.horizontal_side_panel, props.className].join(" ")
      }
      ref={panelRef}
    >
      <button
        className={
          "className" in props && !expandPanel
            ? [classes.expand_button, classes.hover_expand].join(" ")
            : classes.expand_button
        }
        type="button"
        onClick={() => setExpandPanel((prev) => !prev)}
      >
        <span />
        {expandPanel ? <>&#10094;</> : <>&#10095;</>}
      </button>
      <div className={classes.content_panel}>{props.children}</div>
    </div>
  );
};

export default HorizontalSidePanel;
