import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import classes from "./PopupMenu.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "8px 0",
  },
  formControl: {
    margin: theme.spacing(3),
    marginRight: "12px",
    marginLeft: "12px",
  },
}));

const PopupMenuSubSystem = (props) => {
  const classesStyle = useStyles();
  const [list_1, setList_1] = useState([]);
  const [list_2, setList_2] = useState([]);

  useEffect(() => {
    if (props.subsystems.length > 0) {
      if (props.subsystems.length > 5 && props.subsystems.length <= 10) {
        setList_1(props.subsystems.slice(0, 5));
        setList_2(props.subsystems.slice(5, props.subsystems.length));
      } else if (Math.ceil(props.subsystems.length / 2) > 5) {
        setList_1(
          props.subsystems.slice(0, Math.ceil(props.subsystems.length / 2))
        );
        setList_2(
          props.subsystems.slice(
            Math.ceil(props.subsystems.length / 2),
            props.subsystems.length
          )
        );
      } else setList_1(props.subsystems);
    }
  }, [props.subsystems]);

  return (
    <div className={classesStyle.root}>
      <FormControl component="fieldset" className={classesStyle.formControl}>
        <FormLabel
          component="legend"
          style={{ marginBottom: "5px", padding: "0 16px" }}
        >
          Επιλέξτε υποσύστημα
        </FormLabel>
        <FormGroup>
          {list_1.map((item, index) => (
            <MenuItem key={index} onClick={() => props.setOption(item.id)}>
              {item.name}
            </MenuItem>
          ))}
        </FormGroup>
      </FormControl>
      {list_2.length > 0 && (
        <FormControl component="fieldset" className={classesStyle.formControl}>
          <FormLabel
            component="legend"
            style={{ visibility: "hidden", marginBottom: "5px" }}
          >
            Επιλέξτε υποσύστημα
          </FormLabel>
          <FormGroup>
            {list_2.map((item, index) => (
              <MenuItem
                key={index + list_1.length}
                onClick={() => props.setOption(item.id)}
              >
                {item.name}
              </MenuItem>
            ))}
          </FormGroup>
        </FormControl>
      )}
    </div>
  );
};
export default PopupMenuSubSystem;
