import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./other/reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "./assets/fonts/static/Commissioner-Regular.ttf";

import configureTypesStore from "./hooks/types-store";
import configureSensorsStore from "./hooks/sensors-store";
// import configureMarkStore from "./hooks/mark-store";

configureTypesStore();
configureSensorsStore();
// configureMarkStore();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
