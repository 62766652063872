import { initStore } from "./store";
import enableDisableActive from "../services/enableDisableActive";

const configureStore = () => {
  console.log("Configure");
  const actions = {
    CLICKED_SENSOR: (curState, sensorId) => {
      console.log("CLICKED_SENSOR");
      const sensorIndex = curState.sensors.findIndex((s) => s.id === sensorId);
      const tempSensors = [...curState.sensors];
      const updatedSensors = enableDisableActive(tempSensors, sensorIndex);
      return { sensors: updatedSensors };
    },
    DISABLE_SENSORS: (curState) => {
      console.log("DISABLE_SENSORS");
      const tempSensors = [...curState.sensors];
      const updatedSensors = enableDisableActive(tempSensors, null);
      return { sensors: updatedSensors };
    },
    ADD_METRICS: (curState, data) => {
      const tempSensors = [...curState.sensors];
      for (let y = 0; y < tempSensors.length; y++) {
        if (tempSensors[y].id in data) {
          tempSensors[y] = {
            ...tempSensors[y],
            metrics: data[tempSensors[y].id].metrics,
            tooltip: data[tempSensors[y].id].tooltip,
          };
        }
      }

      return { sensors: tempSensors };
    },
    ADD_SENSORS: (curState, data) => {
      const tempSensors = [...curState.sensors, ...data];

      return { sensors: tempSensors };
    },
    INITIALIZE_SENSORS: (state, sensors) => ({ sensors: sensors }),
  };
  initStore(actions, { sensors: [] });
};
export default configureStore;
