import * as React from "react";

const SvgTermsofuse2 = (props) => (
  <svg
    id="termsofuse2_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 46 46"
    style={{
      enableBackground: "new 0 0 46 46",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".termsofuse2_svg__st1{fill:#fff}"}</style>
    <path d="M42 45H4c-1.7 0-3-1.4-3-3V4c0-1.7 1.4-3 3-3h38c1.7 0 3 1.4 3 3v38c0 1.6-1.4 3-3 3z" />
    <path
      className="termsofuse2_svg__st1"
      d="M42 46H4c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4h38c2.2 0 4 1.8 4 4v38c0 2.2-1.8 4-4 4zM4 2c-1.1 0-2 .9-2 2v38c0 1.1.9 2 2 2h38c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4z"
    />
    <path
      className="termsofuse2_svg__st1"
      d="M20.2 36.2c-.1-.5-.2-1-.3-1.4 2.3-1.1 4.6-2.1 7-3.1 0 .4.1.8.1 1.2-2.3 1-4.6 2.1-6.8 3.3m-.7-3.6c-.1-.5-.2-1-.2-1.5 3.5-1.3 7.1-2.4 10.8-3.5v1.2c-3.6 1.1-7.2 2.4-10.6 3.8m-.6-3.7c-.1-.5-.1-1.1-.2-1.6 3.6-1 7.3-1.8 11.1-2.5 0 .4 0 .8-.1 1.2-3.6.8-7.2 1.8-10.8 2.9m-.4-4c0-.6-.1-1.1-.1-1.7 3.4-.5 6.8-1 10.2-1.4 0 .4-.1.9-.1 1.3-3.4.6-6.7 1.2-10 1.8m-.3-4.1c0-.6 0-1.2-.1-1.7 4.3-.2 8.5-.4 12.9-.5-.1.4-.1.8-.2 1.3-4.2.2-8.4.5-12.6.9m0-4.3v-1.8c4.1.2 8.3.4 12.5.7-.1.4-.2.9-.2 1.3-4.2-.2-8.3-.2-12.3-.2m.1-4.6c0-.6.1-1.3.1-1.9.9.1 1.9.3 2.8.4-.1.6-.1 1.2-.2 1.8-.8-.1-1.8-.2-2.7-.3m5.2.6c.1-.6.1-1.1.2-1.7 1.5.2 3.1.5 4.6.7L28 13c-1.5-.2-3-.4-4.5-.5m12-2.7C28.8 8.2 22 6.6 15.3 5c-1 12.1.1 24.1 3.3 35.5 4.3-2.8 8.8-5.3 13.6-7.5-.2-8 1-15.8 3.3-23.2"
    />
    <path
      className="termsofuse2_svg__st1"
      d="M14.8 5.9c-.8-.2-1.6-.3-2.4-.5-.6 12.1 1 23.9 4.6 35 .4-.3.7-.5 1.1-.8-3-10.8-4.2-22.2-3.3-33.7z"
    />
    <path
      className="termsofuse2_svg__st1"
      d="M11.8 6.3c-.5-.1-.9-.2-1.4-.2-.3 11.7 1.5 23.1 5.2 33.7.1-.1.3-.2.4-.3-3.2-10.6-4.7-21.8-4.2-33.2z"
    />
  </svg>
);

export default SvgTermsofuse2;
